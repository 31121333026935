/**
 * Farlo UI | CRM
 *
 * @author Farlo
 */

// Farlo UI MC
class FarloUiCrm {
	constructor(_config) {
		const _self = this;
		let _instance = null;
		let crmAjaxHandler = null;

		if (typeof farloUiMcAjaxHandler !== 'undefined') {
			crmAjaxHandler = farloUiMcAjaxHandler;
		} else if (typeof farloUiSpektrixAjaxHandler !== 'undefined') {
			crmAjaxHandler = farloUiSpektrixAjaxHandler;
		} else {
			crmAjaxHandler = farloAjaxHandler;
		}

		this.config = {
			formId: '',
			nextBtn: '.farlo-ui-crm-next',
			prevBtn: '.farlo-ui-crm-prev',
			navContainer: '.farlo-ui-crm-nav',
			closeBtn: '.farlo-ui-crm-close',
			tabClass: '.farlo-ui-crm-tab',
			stepsClass: '.farlo-ui-crm-step',
			initData: null,
			startPage: 0,
			validateFirst: false
		};
		this.params = {
			initialised: false,
			currentStep: 0,
			totalSteps: 0,
			emailHash: '',
			data: new FormData()
		};

		this.init = (config) => {
			if (_self.params.initialised) {
				_self.toggleForm();
			}
			window._farlo.events.push('Signup', 'Started');

			if (typeof config === 'string') {
				_self.config.formId = config;
			} else if (typeof config === 'object') {
				_self.config = {
					..._self.config,
					...config
				};
			} else {
				console.log('No Form ID set');
				return false;
			}
			_instance = document.getElementById(_self.config.formId);
			_self.params.totalSteps = _instance.querySelectorAll(_self.config.stepsClass).length;
			if (_self.params.totalSteps === 1) {
				_instance.querySelector(_self.config.stepsClass).style.display = 'none';
			}

			if (_self.config.initData) {
				for (const i in _self.config.initData) {
					if (_instance.querySelector('#' + i)) {
						if (_instance.querySelector('#' + i).type === 'checkbox') {
							_instance.querySelector('#' + i).checked = _self.config.initData[ i ];
						} else {
							_instance.querySelector('#' + i).value = _self.config.initData[ i ];
						}
					}
				}
				if (_self.config.validateFirst) {
					_self.nextPrev(1);
				} else {
					_self.showPage(_self.config.startPage);
				}
			} else {
				_self.showPage(_self.config.startPage);
			}

			_self.toggleForm();

			_instance.querySelector(_self.config.nextBtn).addEventListener('click', () => {
				_self.nextPrev(1);
			});
			_instance.querySelector(_self.config.prevBtn).addEventListener('click', () => {
				_self.nextPrev(-1);
			});
			_instance.querySelectorAll(_self.config.closeBtn).forEach((btn) => {
				btn.addEventListener('click', (e) => {
					e.stopImmediatePropagation();
					_self.toggleForm();
				});
			});
			_self.params.initialised = true;
		};

		this.toggleForm = () => {
			if (_instance.style.display === 'flex') {
				_instance.style.display = 'none';
			} else {
				_instance.style.display = 'flex';
			}
		};

		this.prepareMailchimpData = (n, x) => {
			_self.params.data.append('action', 'farlo-ui-modify-subscriber');
			_self.params.data.append('hash', _self.params.emailHash);

			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-textbox').forEach((el) => {
				if (el.value !== '') {
					_self.params.data.append('FARLO_UI_MC_' + el.id, el.value);
				}
			});

			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-hidden').forEach((el) => {
				if (el.value !== '') {
					_self.params.data.append('FARLO_UI_MC_' + el.id, el.value);
				}
			});

			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-form-item-single_checkbox').forEach((field) => {
				const el = field.querySelector('.farlo-ui-crm-input');
				if (el.checked !== '') {
					_self.params.data.append('FARLO_UI_MC_' + el.id, el.value);
				}
			});

			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-form-item-single_choice_field').forEach((field) => {
				let id = field.querySelector('.farlo-ui-crm-choice').id;
				id = id.replace('farlo-ui-crm-choice-', '');
				const choices = [];
				field.querySelectorAll('.farlo-ui-crm-input').forEach((el) => {
					if (el.checked) {
						choices.push(field.querySelector("label[for='" + el.id + "']").innerText);
					}
				});
				_self.params.data.append('FARLO_UI_MC_' + id, choices.toString());
			});

			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-form-item-multiple_choice_field').forEach((field) => {
				let id = field.querySelector('.farlo-ui-crm-choice').id;
				id = id.replace('farlo-ui-crm-choice-', '');
				const choices = [];
				field.querySelectorAll('.farlo-ui-crm-input').forEach((el) => {
					if (el.checked) {
						choices.push(field.querySelector("label[for='" + el.id + "']").innerText);
					}
				});
				_self.params.data.append('FARLO_UI_MC_' + id, choices.toString());
			});
		};

		this.prepareDotDigitalData = (n, x) => {
			let email = '';
			const dataFields = [];

			// Collect email and other text-based inputs
			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-input').forEach((el) => {
				const id = el.id.split("___")[0];
				if (el.type === 'email') {
					email = el.value;
				} else if (el.value !== '') {
					dataFields.push({ key: id, value: el.value });
				}
			});

			// Handle single choice fields (radio buttons)
			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-form-item-single_choice_field').forEach((field) => {
				const selected = field.querySelector('.farlo-ui-crm-input:checked');
				if (selected) {
					const key = field.id.split("___")[0];
					dataFields.push({ key, value: selected.value });
				}
			});

			// Handle multiple choice fields (checkboxes)
			x[ _self.params.currentStep ].querySelectorAll('.farlo-ui-crm-form-item-multiple_choice_field').forEach((field) => {
				const choices = [];
				field.querySelectorAll('.farlo-ui-crm-input:checked').forEach((checkbox) => {
					choices.push(checkbox.value);
				});
				if (choices.length > 0) {
					const key = field.id.split("___")[0]; // Adjust based on your form structure
					dataFields.push({ key, value: choices.join(', ') });
				}
			});

			_self.params.data.append('action', 'farlo-ui-dd-modify-contact');
			_self.params.data.append('email', email);
			_self.params.data.append('dataFields', JSON.stringify(dataFields));
		};

		this.submitData = (n, x) => {
			const isSpektrix = document.getElementById(_self.config.formId).classList.contains('farlo-ui-crm__spektrix');
			const isMailchimp = document.getElementById(_self.config.formId).classList.contains('farlo-ui-crm__mailchimp');

			window._farlo.events.push('Signup', 'Stage Complete', (_self.params.currentStep + 1));
			_self.params.data.append('nonce', crmAjaxHandler.nonce);

			if (crmAjaxHandler) {
				if (isMailchimp) {
					_self.prepareMailchimpData(n, x);
				} else if (isSpektrix) {
					_self.prepareDotDigitalData(n, x);
				}
			}

			fetch(crmAjaxHandler.ajaxurl, {
				method: 'POST',
				credentials: 'same-origin',
				body: _self.params.data
			}).then((response) => response.json()).then((data) => {
				if (!data || !data.success) {
					throw new Error(`Request failed with status ${ data.detail }`);
				}
				if (data.data.email_hash) {
					_self.params.emailHash = data.data.email_hash;
				}

				x[ _self.params.currentStep ].classList.remove('loading');
				x[ _self.params.currentStep ].style.display = 'none';
				_self.params.currentStep = _self.params.currentStep + n;
				_self.showPage(_self.params.currentStep);
			});
		};

		this.showPage = (n) => {
			_instance.querySelector(_self.config.navContainer).style.display = 'flex';
			const x = _instance.querySelectorAll(_self.config.tabClass);
			x[ n ].style.display = 'block';
			if (n === 0) {
				_instance.querySelector(_self.config.prevBtn).style.display = 'none';
			} else {
				_instance.querySelector(_self.config.prevBtn).style.display = 'inline';
			}
			if (n === (x.length - 2)) {
				_instance.querySelector(_self.config.nextBtn).innerHTML = 'Submit';
			} else {
				_instance.querySelector(_self.config.nextBtn).innerHTML = 'Next';
			}
			if (n === (x.length - 1)) {
				_instance.querySelector(_self.config.navContainer).style.display = 'none';
			}
			_self.indicatorStep(n);
		};

		this.nextPrev = (n) => {
			if (n === 1 && !_self.validatePage()) {
				return false;
			}
			const x = _instance.querySelectorAll(_self.config.tabClass);

			if (n === 1) {
				x[ _self.params.currentStep ].classList.add('loading');
				_self.submitData(n, x);
			} else {
				x[ _self.params.currentStep ].style.display = 'none';
				_self.params.currentStep = _self.params.currentStep + n;
				_self.showPage(_self.params.currentStep);
			}
		};

		this.validatePage = () => {
			let i,
				valid = true;
			const x = _instance.querySelectorAll(_self.config.tabClass);
			const y = x[ _self.params.currentStep ].getElementsByTagName('input');
			for (i = 0; i < y.length; i += 1) {
				if (y[ i ].type === 'email') {
					if (!window._farlo.helpers.validators.email(y[ i ].value)) {
						y[ i ].className += ' invalid';
						valid = false;
					}
				} else if (y[ i ].type === 'checkbox' && y[ i ].required) {
					if (!y[ i ].checked) {
						y[ i ].className += ' invalid';
						valid = false;
					}
				} else if (y[ i ].value === '' && y[ i ].required) {
					y[ i ].className += ' invalid';
					valid = false;
				}
			}
			if (valid) {
				_instance.querySelectorAll(_self.config.stepsClass)[ _self.params.currentStep ].className += ' finish';
			}
			return valid;
		};

		this.indicatorStep = (n) => {
			if (n < _self.params.totalSteps) {
				let i;
				const x = _instance.querySelectorAll(_self.config.stepsClass);
				for (i = 0; i < x.length; i ++) {
					x[ i ].className = x[ i ].className.replace(' active', '');
				}
				x[ n ].className += ' active';
			} else {
				window._farlo.events.push('Signup', 'Complete');
			}
		};

		_self.init(_config);
	}
}

export default FarloUiCrm;
