export function merchandiseAdd() {
        document.querySelectorAll('.spektrix-merchandise').forEach(function(component) {
            component.addEventListener('spektrix-add-to-basket-success', function(event) {
                console.log('Item added to basket:', event.detail);
                // You can add further logic here, like showing a success message or redirecting the user
            });
    
            component.addEventListener('spektrix-add-to-basket-failure', function(event) {
                console.error('Failed to add item to basket:', event.detail);
                // Handle the error, e.g., show an error message to the user
            });
        });
}