class FarloUiSelectBox {
	constructor(element) {
		this.element = element;
		this.selectMutationObserver = null;

		this.initialiseSelect(this.element);
	}

	initialiseSelect(selectBox) {
		if (selectBox.parentNode.classList.contains('select')) {
			return; // bail out if already instantiated
		}

		selectBox.classList.add('site-farlo-ui-selectbox__native');
		const selectField = this.createselectFieldElement(selectBox);
		const selectDropdown = selectField.querySelector('.site-farlo-ui-selectbox__dropdown');
		selectDropdown.innerHTML = this.createSelectOptions(selectBox);

		this.setupEvents(selectBox, selectField, selectDropdown);
		this.observeSelectChanges(selectBox, () => this.refreshSelect(selectBox, selectField, selectDropdown));

		this.updateVisibleSelection(selectBox, selectDropdown.querySelector('.site-farlo-ui-selectbox__option--is-selected'));
	}

	removeSelect(selectBox) {
		const selectField = selectBox.parentNode;
		if (selectField.classList.contains('select')) {
			selectBox.classList.remove('site-farlo-ui-selectbox__native');
			this.selectMutationObserver.disconnect();
			selectField.parentNode.insertBefore(selectBox, selectField);
			selectField.parentNode.removeChild(selectField);
		}
	}

	createselectFieldElement(selectBox) {
		const selectField = document.createElement('div');
		selectField.classList.add('site-farlo-ui-selectbox');
		if (selectBox.disabled) {
			selectField.classList.add('site-farlo-ui-selectbox--disabled');
		}
		selectField.setAttribute('tabindex', '-1');
		selectField.innerHTML = '<div class="site-farlo-ui-selectbox__simulated"></div><div class="site-farlo-ui-selectbox__dropdown" role="listbox"></div>';

		selectBox.parentNode.insertBefore(selectField, selectBox);
		selectField.appendChild(selectBox);

		return selectField;
	}

	createSelectOptions(selectBox) {
		let optionHtml = '';
		let index = 0;

		if (selectBox.children.length) {
			Array.from(selectBox.children).forEach((child) => {
				if (child.tagName === 'OPTGROUP') {
					optionHtml += `<div class="site-farlo-ui-selectbox__optgroup"><div class="site-farlo-ui-selectbox__optgroup-heading">${ child.getAttribute('label') }</div>`;
					Array.from(child.children).forEach((option) => {
						optionHtml += this.createOptionMarkup(option, index ++);
					});
					optionHtml += '</div>';
				} else {
					optionHtml += this.createOptionMarkup(child, index ++);
				}
			});
		}

		return optionHtml;
	}

	createOptionMarkup(option, index) {
		const isDisabled = option.disabled || (option.parentElement.tagName === 'OPTGROUP' && option.parentElement.disabled);

		return `<div class="site-farlo-ui-selectbox__option${ isDisabled ? ' site-farlo-ui-selectbox__option--is-disabled' : '' }${ option.selected ? ' site-farlo-ui-selectbox__option--is-selected' : '' }" ${ !isDisabled ? 'tabindex="0"' : '' } role="option" data-index="${ index }">${ option.innerText }</div>`;
	}

	setupEvents(selectBox, selectField, selectDropdown) {
		const selectBoxSimulated = selectField.querySelector('.site-farlo-ui-selectbox__simulated');
		const selectBoxOptions = selectDropdown.getElementsByClassName('site-farlo-ui-selectbox__option');

		selectBoxSimulated.addEventListener('click', () => {
			this.toggleDropdown(selectField, selectDropdown);
		});

		Array.from(selectBoxOptions).forEach((optionItem) => {
			optionItem.addEventListener('click', () => {
				if (!optionItem.classList.contains('site-farlo-ui-selectbox__option--is-disabled')) {
					this.selectOption(selectBox, optionItem, selectDropdown);
				}
			});
		});

		document.addEventListener('click', (event) => {
			if (!selectField.contains(event.target)) {
				this.hideDropdown(selectField, selectDropdown);
			}
		});

		selectBox.addEventListener('focus', () => {
			selectField.classList.add('site-farlo-ui-selectbox--is-focused');
		});

		selectBox.addEventListener('blur', () => {
			selectField.classList.remove('site-farlo-ui-selectbox--is-focused');
		});

		selectField.addEventListener('keydown', (event) => {
			this.handleKeyboardNavigation(event, selectBox, selectField, selectDropdown);
		});
	}

	toggleDropdown(selectField, selectDropdown) {
		const isActive = selectField.classList.contains('site-farlo-ui-selectbox--is-active');
		if (isActive) {
			this.hideDropdown(selectField, selectDropdown);
		} else {
			this.showDropdown(selectField, selectDropdown);
		}
	}

	showDropdown(selectField, selectDropdown) {
		let selectedOption = selectDropdown.querySelectorAll('.site-farlo-ui-selectbox__option--is-selected');

		// Remove existing selections
		Array.from(selectedOption).forEach((el) => {
			el.classList.remove('site-farlo-ui-selectbox__option--is-selected');
		});

		// Highlight the currently selected option
		selectedOption = selectDropdown.querySelector(`.site-farlo-ui-selectbox__option[data-index="${ this.element.selectedIndex }"]`);
		if (selectedOption) {
			selectedOption.classList.add('site-farlo-ui-selectbox__option--is-selected');
		}

		selectField.classList.add('site-farlo-ui-selectbox--is-active');

		// Calculate dropdown position and adjust if needed
		const dropdownOffset = selectDropdown.getBoundingClientRect();
		const bottomOfViewport = document.documentElement.clientHeight + window.scrollY;

		if (dropdownOffset.top + selectDropdown.clientHeight > bottomOfViewport) {
			if (dropdownOffset.top > bottomOfViewport - dropdownOffset.top) {
				selectDropdown.style.maxHeight = (dropdownOffset.top * 0.8) + 'px';
				selectField.classList.add('site-farlo-ui-selectbox--is-above');
			} else {
				selectDropdown.style.maxHeight = ((document.documentElement.clientHeight - dropdownOffset.top) * 0.8) + 'px';
				selectField.classList.remove('site-farlo-ui-selectbox--is-above');
			}
		} else {
			selectDropdown.style.maxHeight = '';
			selectField.classList.remove('site-farlo-ui-selectbox--is-above');
		}

		selectDropdown.style.display = 'block';
	}

	hideDropdown(selectField, selectDropdown) {
		selectField.classList.remove('site-farlo-ui-selectbox--is-active');
		selectDropdown.style.display = 'none';
		selectField.classList.remove('site-farlo-ui-selectbox--is-above');
		selectDropdown.style.maxHeight = '';
	}

	selectOption(selectBox, selectboxOption, selectDropdown) {
		const index = selectboxOption.getAttribute('data-index');
		const selectField = selectBox.parentNode; // Assuming the parent is the select field

		selectBox.selectedIndex = index;
		selectBox.dispatchEvent(new Event('change'));
		this.updateSelectedOption(selectDropdown, index);
		this.updateVisibleSelection(selectBox, selectboxOption); // Update the visible selection

		// Hide dropdown after selection
		this.hideDropdown(selectField, selectDropdown);
	}

	updateVisibleSelection(selectBox, selectboxOption) {
		// Update the visible part of the custom select box to reflect the selected value
		const selectedText = selectboxOption.innerText;
		const simulated = selectBox.parentNode.querySelector('.site-farlo-ui-selectbox__simulated');
		if (simulated) {
			simulated.textContent = selectedText;
		}
	}

	updateSelectedOption(selectDropdown, selectedIndex) {
		const selectedOption = selectDropdown.querySelector('.site-farlo-ui-selectbox__option--is-selected');
		if (selectedOption) {
			selectedOption.classList.remove('site-farlo-ui-selectbox__option--is-selected');
		}
		const newselectedOption = selectDropdown.querySelector(`.site-farlo-ui-selectbox__option[data-index="${ selectedIndex }"]`);
		if (newselectedOption) {
			newselectedOption.classList.add('site-farlo-ui-selectbox__option--is-selected');
		}
	}

	handleKeyboardNavigation(event, selectBox, selectField, selectDropdown) {
		const key = event.key;
		const isActive = selectField.classList.contains('site-farlo-ui-selectbox--is-active');
		const selectBoxOptions = Array.from(selectDropdown.getElementsByClassName('site-farlo-ui-selectbox__option'));
		const selectedOption = selectDropdown.querySelector('.site-farlo-ui-selectbox__option--is-selected');
		let newIndex;

		switch (key) {
			case 'ArrowDown':
				event.preventDefault();
				if (!isActive) {
					this.showDropdown(selectField, selectDropdown);
				} else if (selectedOption) {
					newIndex = (parseInt(selectedOption.getAttribute('data-index')) + 1) % selectBoxOptions.length;
					this.highlightOption(selectBoxOptions, newIndex);
				} else {
					this.highlightOption(selectBoxOptions, 0);
				}
				break;

			case 'ArrowUp':
				event.preventDefault();
				if (!isActive) {
					this.showDropdown(selectField, selectDropdown);
				} else if (selectedOption) {
					newIndex = parseInt(selectedOption.getAttribute('data-index')) - 1;
					if (newIndex < 0) {
						newIndex = selectBoxOptions.length - 1;
					}
					this.highlightOption(selectBoxOptions, newIndex);
				} else {
					this.highlightOption(selectBoxOptions, selectBoxOptions.length - 1);
				}
				break;

			case 'Enter':
				if (isActive && selectedOption) {
					event.preventDefault();
					this.selectOption(selectBox, selectedOption, selectDropdown);
				}
				break;

			case 'Escape':
				event.preventDefault();
				if (isActive) {
					this.hideDropdown(selectField, selectDropdown);
				}
				break;

			case 'Tab':
				this.hideDropdown(selectField, selectDropdown);
				break;
		}
	}

	highlightOption(selectBoxOptions, index) {
		selectBoxOptions.forEach((_sel) => _sel.classList.remove('site-farlo-ui-selectbox__option--is-selected'));
		selectBoxOptions[ index ].classList.add('site-farlo-ui-selectbox__option--is-selected');
		selectBoxOptions[ index ].scrollIntoView({ block: 'nearest' });
	}

	observeSelectChanges(selectBox, callback) {
		this.selectMutationObserver = new MutationObserver(callback);
		this.selectMutationObserver.observe(selectBox, {
			characterData: true,
			childList: true,
			subtree: true,
			attributes: true
		});
	}

	refreshSelect(selectBox, selectField, selectDropdown) {
		// Refresh the dropdown's inner HTML
		selectDropdown.innerHTML = this.createSelectOptions(selectBox);
		selectField.classList.toggle('site-farlo-ui-selectbox--disabled', selectBox.disabled);

		// Re-setup the events for the new option elements
		this.setupEvents(selectBox, selectField, selectDropdown);
	}
}

export default FarloUiSelectBox;
